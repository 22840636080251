import axios from '@/axios.js'

const baseUrl = `v4/apps`;

export default {
    getApplications : async (params, page = 1) => {
        try {
            const response = await axios.adminapi.get(baseUrl, { params: {
                ...params,
                page: page
                }
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    }
}
