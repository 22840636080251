<template>
    <div>
        <label v-if="label" class="vs-select--label">{{ label }}</label>

        <div class="relative" v-click-outside="closeDropdown">
            <div
                @click="toggleDropdown"
                @keydown.enter.prevent="toggleDropdown"
                @keydown.space.prevent="toggleDropdown"
                @keydown.down.prevent="openDropdown"
                @keydown.esc.prevent="closeDropdown"
                class="bg-white border border-grey rounded-lg p-2 flex items-center justify-between cursor-pointer"
                tabindex="0"
                role="combobox"
                :aria-expanded="isOpen"
                aria-owns="dropdown-"
                :aria-activedescendant="activeDescendant"
            >
                <div class="flex flex-wrap gap-1 overflow-hidden">
                    <template v-if="multiple">
                        <vs-chip
                            v-for="option in selectedOptions"
                            :key="option.key"
                            color="primary"
                            class="mb-0 rounded-md chip"
                            closable
                            @click="removeOption(option)"
                        >
                            {{ option.text }}
                        </vs-chip>
                    </template>
                    <template v-else>
                        <span class="text-black">{{ selectedOptions.length ? selectedOptions[0].text : placeholder }}</span>
                    </template>
                </div>
                <div class="w-3">
                    <svg class="w-3 h-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </div>

            </div>
            <div v-if="isOpen" class="absolute z-10 w-full mt-1 bg-white border border-grey rounded-lg overflow-hidden">
                <input
                    ref="searchInput"
                    v-model="search"
                    @input="filterOptions"
                    @keydown.down.prevent="navigateOptions('down')"
                    @keydown.up.prevent="navigateOptions('up')"
                    @keydown.enter.prevent="selectFocusedOption"
                    @keydown.esc.prevent="closeDropdown"
                    class="w-full p-2 border-t-0 border-l-0 border-r-0 border-b-1 border-grey rounded-lg focus:outline-none"
                    placeholder="Search options..."
                />
                <ul
                    id="dropdown"
                    role="listbox"
                    :aria-multiselectable="multiple"
                    class="overflow-y-scroll listbox"
                >
                    <li
                        v-for="(option, index) in filteredOptions"
                        :key="option.key"
                        :id="'option-' + option.key"
                        @click="toggleOption(option)"
                        @keydown.enter.prevent="toggleOption(option)"
                        @keydown.space.prevent="toggleOption(option)"
                        :ref="'option-' + index"
                        :class="{
            'p-2 cursor-pointer hover:bg-primary hover:text-white': true,
            'bg-primary text-white': index === focusedOptionIndex || isSelected(option),
            'opacity-50 cursor-not-allowed': isDisabled(option),
          }"
                        :tabindex="isDisabled(option) ? -1 : 0"
                        role="option"
                        :aria-selected="isSelected(option)"
                        :aria-disabled="isDisabled(option)"
                    >
                        <template v-if="multiple">
                            <input
                                type="checkbox"
                                :checked="isSelected(option)"
                                :disabled="isDisabled(option)"
                                class="mr-2 hidden"
                            />
                        </template>
                        {{ option.text }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true,
            validator: (value) => value.every(option => 'key' in option && 'text' in option)
        },
        disabledOptionIds: {
            type: Array,
            default: () => []
        },
        value: {
            type: [Array, String, Number],
            default: () => []
        },
        multiple: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Select option'
        },
        label: {
            default: null
        }
    },
    data() {
        return {
            isOpen: false,
            search: '',
            filteredOptions: [],
            focusedOptionIndex: -1,
            activeDescendant: null
        }
    },
    computed: {
        selectedOptions() {
            if (this.multiple) {
                return this.options.filter(option => this.value.includes(option.key))
            } else {
                return this.options.filter(option => option.key === this.value)
            }
        }
    },
    watch: {
        options: {
            immediate: true,
            handler(newOptions) {
                this.filteredOptions = newOptions
            }
        }
    },
    directives: {
        clickOutside: {
            bind(el, binding, vnode) {
                el.clickOutsideEvent = function(event) {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event)
                    }
                }
                document.body.addEventListener('click', el.clickOutsideEvent)
            },
            unbind(el) {
                document.body.removeEventListener('click', el.clickOutsideEvent)
            }
        }
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen
            if (this.isOpen) {
                this.$nextTick(() => {
                    this.$refs.searchInput.focus()
                })
            }
        },
        openDropdown() {
            if (!this.isOpen) {
                this.isOpen = true
                this.$nextTick(() => {
                    this.$refs.searchInput.focus()
                })
            }
        },
        closeDropdown() {
            this.isOpen = false
            this.search = ''
            this.filterOptions()
            this.focusedOptionIndex = -1
        },
        toggleOption(option) {
            if (this.isDisabled(option)) return

            if (this.multiple) {
                const newValue = this.isSelected(option)
                    ? this.value.filter(key => key !== option.key)
                    : [...this.value, option.key]
                this.$emit('input', newValue)
            } else {
                this.$emit('input', option.key)
                this.closeDropdown()
            }
        },
        removeOption(option) {
            if (this.multiple) {
                this.$emit('input', this.value.filter(key => key !== option.key))
            } else {
                this.$emit('input', null)
            }
        },
        filterOptions() {
            this.filteredOptions = this.options.filter(option =>
                option.text.toLowerCase().includes(this.search.toLowerCase())
            )
            this.focusedOptionIndex = -1
        },
        isSelected(option) {
            return this.multiple
                ? this.value.includes(option.key)
                : this.value === option.key
        },
        isDisabled(option) {
            return this.disabledOptionIds.includes(option.key)
        },
        navigateOptions(direction) {
            if (direction === 'down') {
                this.focusedOptionIndex = (this.focusedOptionIndex + 1) % this.filteredOptions.length
            } else if (direction === 'up') {
                this.focusedOptionIndex = (this.focusedOptionIndex - 1 + this.filteredOptions.length) % this.filteredOptions.length
            }
            this.scrollToFocusedOption()
            this.updateActiveDescendant()
        },
        scrollToFocusedOption() {
            this.$nextTick(() => {
                const focusedOption = this.$refs[`option-${this.focusedOptionIndex}`]
                if (focusedOption && focusedOption[0]) {
                    focusedOption[0].scrollIntoView({ block: 'nearest' })
                }
            })
        },
        updateActiveDescendant() {
            const focusedOption = this.filteredOptions[this.focusedOptionIndex]
            this.activeDescendant = focusedOption ? `option-${focusedOption.key}` : null
        },
        selectFocusedOption() {
            if (this.focusedOptionIndex >= 0 && this.focusedOptionIndex < this.filteredOptions.length) {
                this.toggleOption(this.filteredOptions[this.focusedOptionIndex])
            }
        }
    }
}
</script>

<style scoped>
    .listbox {
        max-height: 300px;
        scrollbar-color: #3560DA white !important;
        scrollbar-width: thin;
    }
</style>
