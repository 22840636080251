<template>
    <div class="vx-card p-8 pt-0">
        <div v-if="!isEdit" class="w-1/2">
            <LabelForm>{{ $t('dashboard.app') }}</LabelForm>

            <Combobox class="mt-2"
                :options="applications.map(app => ({ key: app.uuid, text: app.name }))" v-model="selectedApplication" />
        </div>

        <FeaturesCheckboxes ref="featuresCheckboxes" :object="scope" />

        <div class="flex flex-row gap-4 mt-12">
            <button class="sk-btn sk-btn-primary rounded-lg btn-border" @click="handleCancel">
                {{ $t('apps.modals.cancel') }}
            </button>
            <button class="sk-btn sk-btn-primary rounded-lg" @click="handleSave">
                {{ $t('Save') }}
            </button>
        </div>
    </div>
</template>

<script>

import Combobox from "@/modules/Shared/Components/Combobox.vue";
import loader from "@/modules/Shared/Mixins/loader";
import AuthorizationService from "@/modules/Admin/Services/AuthorizationService";
import LabelForm from "@/modules/Shared/Components/form/LabelForm.vue";
import FeaturesCheckboxes from "@/modules/Admin/Components/FeaturesCheckboxes.vue";
import ApplicationService from '@/modules/Shared/Services/ApplicationService';
import { unifyPaginatedResponse } from "@/modules/Shared/Helpers/apiResponseHelper";
import PaginationService from "@/modules/Shared/Services/PaginationService";

export default {
    name: "AppScopeForm",
    components: { FeaturesCheckboxes, LabelForm, Combobox },
    mixins: [loader],
    props: {
        scope: {
            type: Object,
            required: true,
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            applications: [],
            selectedApplication: null
        }
    },
    watch: {
        selectedApplication(val) {
            if (!val) {
                this.scope.object_id = null
                this.scope.object_uuid = null
                return
            }

            let app = this.applications.find(app => app.uuid === val)
            this.scope.object_id = app.id
            this.scope.object_uuid = app.uuid
        }
    },
    async created() {
        if (!this.isEdit) {
            await this.loadAndNotify(async () => {
                this.applications = await unifyPaginatedResponse(ApplicationService.getApplications, PaginationService.parseParameters({
                    per_page: 30
                }))
            }, true)
        }
    },
    methods: {
        handleCancel() {
            this.$router.push({ name: 'admin-app-features' });
        },
        async handleSave() {
            await this.loadAndNotify(async () => {
                let response;
                const enabledFeatures = this.$refs.featuresCheckboxes.getEnabledFeatures().map(featureId => ({ id: featureId }))

                if (this.isEdit) {
                    response = await AuthorizationService.editScope(this.scope.id, {
                        enabled_features: enabledFeatures
                    })
                } else {
                    response = await AuthorizationService.createScope({
                        object_id: this.scope.object_id,
                        object_uuid: this.scope.object_uuid,
                        scope_type_id: this.scope.scope_type.id,
                        enabled_features: enabledFeatures,
                    })
                }

                if (response.code !== 200) {
                    return
                }

                await this.$router.push({ name: 'admin-app-features' });
            }, false)
        }
    }
};
</script>
